:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Lato.css";
@import "/fontmodules/Lustria.css";

@import "/fontmodules/Montserrat.css";
@import "/cssmodules/animate.css";


/*
%7CHind
%7CRobot
%7CRoboto+Condensed
%7CNews+Cycle
%7CWork+Sans
%7CSignika
%7CTitillium+Web

@import "/theme/intendit/css/modules/fade_zoom.css";
@import "/cssmodules/photoswipe.css";
@import "/cssmodules/animate.css";
*/

.sektion {position:relative; }
.vigordetlattareattlyckas_sektion:after { background: rgba(0, 0, 0, 0) url("/theme/intendit/graphics/curl.png") no-repeat scroll left center / auto 160%;
    bottom: 0;
    content: "";
    height: 100%;
    opacity: 0.5;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;}


.varakunderarintealltidkanda–annu_sektion:after { background: rgba(0, 0, 0, 0) url("/theme/intendit/graphics/curl.png") no-repeat scroll right center / auto 130%;
    bottom: 0;
    content: "";
    height: 100%;
    opacity: 0.5;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;}



.omoss_sektion:after { background: rgba(0, 0, 0, 0) url("/theme/intendit/graphics/curl.png") no-repeat scroll right center / auto 160%;
    bottom: 0;
    content: "";
    height: 100%;
    opacity: 0.5;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;}

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #303030;
      line-height:1.25em;
    font-size:1em; 
      font-family: 'Lustria', serif;
      /*background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/;}
 
/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh; margin-top:80px;} 
.startsida .wrapper {min-height:1em; }
.container {width:100%;}
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
.sektion a, .sektion a:hover, .sektion a:focus {color:inherit;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:320px;  max-width:40%; border-radius:50%;  height:auto;  margin: 0 0 30px 30px; overflow:hidden;}
.box_bild, .listing_bild {width:100%; flex:none; max-width:100%;} /*säkra vyn i IE*/
.bild img, .box_bild img,  .galleri_bild img {width:100%; height:auto; display:block;  } /*säkra vyn i IE*/
.listing_bild img {border-radius:50%; width:50%; }
.listing_bild {margin-bottom: 10px; }
 /*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; }
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/

/*------------readmore & buttons----------------*/
.button, .readmore, button {background: rgba(0, 0, 0, 0.15) !important; transition:background 0.5s ease-in 0s; padding: 6px 0 8px; display:block; width: 100%;  margin:0; line-height: 1.2em; border:none;}
.button:hover, .readmore:hover, button:hover {background:rgba(0, 0, 0, 0.3)!important; color:#fff !important; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;  text-align: center; border-radius: 0.2em;} 
 
/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(81, 15, 59, 0.8); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.kontakt_sektion .fa.round {background:rgba(0, 0, 0, 0.15); font-size:1.3em; width: 45px; height: 45px; padding: 12px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {padding: 20px 0; border-bottom:1px solid #e4e4e4; }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:300;   font-family: 'Montserrat', sans-serif; }
h1{font-size: 2.6em;padding: 0px 0 15px; /*text-transform:uppercase;*/}
h2{font-size: 1.45em;}
h3{font-size: 1.25em;  }
h4{font-size: 1em;}
h5{font-size: 1em;}

.highlight {font-size:1.4em; line-height:1.2em; padding: 0px 0 15px ;  font-weight:300;  font-family: 'Montserrat', sans-serif;}
.sida.listing h1 {padding-bottom:40px; margin-bottom:40px; border-bottom:1px solid #e4e4e4;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:90px; z-index:1;   } /*display none by default*/
.topbild {height:45vh; max-height:45vw; background-position: center center; background-size:cover; position: relative;}

/*----------------------Bildspel-----------------------*/
.slick-slider {margin:0 auto; position: relative; border-bottom: 5px solid #e4e4e4; }
.slick-slide {background-size:cover; background-position: center center; min-height:65vh; display:flex !important;  }   
.slick {visibility: hidden; min-height:65vh; }
.slick-initialized {visibility: initial;}
.bildspel_titel {width:100%; text-align:center; font-size: 3.5em; line-height:1.1em; padding: 0px; text-align:center;}
 
 

/*----------------------Startsidatext-----------------------*/
.startsidatext {padding: 40px 0 40px; width:100%; color:#fff; background: rgba(81, 15, 59, 0.85); margin-bottom: 40px; }
.startsidatext h1 { font-size: 2.8em;
    font-weight: 400;
    padding: 0 0 10px;
    text-align: center;
    text-transform: uppercase;}
.startsidatext_textdiv {text-align:center; font-size:1.55em; line-height:1.1em; font-weight:300;   font-family: 'Montserrat', sans-serif;} 

/*----------------------Boxar-------------------------------*/
.boxar {padding: 50px 0 50px; min-height:calc(30vh - 90px); background: url('/theme/intendit/graphics/bg.png') center top repeat fixed; }
.box {}
.box h2 { font-family: 'Montserrat', sans-serif; font-size:1.6em; margin: 0 0 10px; padding: 0 0 10px; border-bottom: 3px solid #d0d0d0; }
 
.medarbetare {text-align:center; }

.medarbetare h2 {font-size:1.25em;  }

.personal {border-bottom: 1px solid #5a5a5a; margin-bottom: 30px;  }

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------ALTERNATIVE INDEX - Onepage-----------------------------------------------------------*/
.sektion {width:100%; padding: 100px 0; position:relative;}
.sektion.full {min-height: 80vh;}
.banner_sektion {min-height:60vh; }
.banner_sektion h1 {font-size:3.4em; text-align:right; font-weight:400;  }



/*arrows*/
.scrollup {opacity:0; position:fixed; bottom:30px; right:30px; z-index:2; color:#fff; font-size:4em; cursor:pointer; transition:opacity 0.5s ease-in 0s;}
body.scrolled .scrollup {opacity:0.3;}  body.scrolled .scrollup:hover {opacity:1; transition: opacity 0.5s ease-in 0s; }

.arrow .fa {  background:#202020; border-radius: 50%; color: #ffffff; display: block; font-family: FontAwesome;font-size: 5em;
height: 80px;left: 50%;margin: -40px 0 0 -40px; padding: 4px 0 0; position: absolute;text-align: center; width: 80px; z-index:5;}


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px;}
.thumbtitel {font-size:90%; }  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; }
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto;}
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 
 
 
/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
.kontakt_sektion {padding-bottom: 0 !important; background:#101010; color:#fff; }
.kontakt_sektion a { color:#fff; }

.kontakt_sektion > .container:first-child {padding-bottom: 60px;}
.kontakt_sektion > .container > .twelve {margin-bottom:0; }

.kontakt_sektion .boltforms-row {width:100%;}
.kontakt_sektion .boltforms-row label {font-weight: 300; margin-bottom: 0;}
.kontakt_sektion .boltforms-row input, .kontakt_sektion .boltforms-row textarea{width:100%; color:#606060; margin-bottom:0; }

/*Googlekarta*/
.map-canvas {height: 40vh; }
.map-canvas img {max-width: none;}

.created-by, .created-by a  {color:#505050 !important; font-size:0.9em; letter-spacing:0.3pt; }
.created-by  {text-align:center; padding: 5px 0 10px; background:#050505; }
.created-by p {margin:0; }

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
/*backbutton på separata sidor*/
.backbutton {padding:0px 20px 0 0; font-size: 1.1em;}
.backbutton .fa {margin-bottom: 2px; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:none; width:100%; background:#fff; z-index:10; position:fixed; top:0; min-height:80px; }
body.scrolled header {}

/*Logotype*/
.logo {padding:15px; box-sizing:border-box; } 
.logo img {max-height: 40px; width:auto; max-width:calc(100vw - 100px)}
.logo a {padding:0; display:block;}  

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; border-bottom: 2px solid rgba(0, 0, 0, 0);  /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a { border-bottom: 2px solid #6D7C79; }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family: FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }

/*Mobile menu under 800px - fixed header*/
.mobile-menu header {box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;  } 
.mobile-menu nav ul.menu {margin-top:1px; background:rgba(255, 255, 255, 0.9); }
.mobile-menu nav ul.menu.show { max-height:calc(100vh - 80px); padding:10px 0;  }  /*räknar höjd - fixed header*/
.mobile-menu nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
.mobile-menu nav .menu .dropdown ul {padding:0; left:0px;} 
.mobile-menu nav ul.menu li.dropdown > a:after {display:none; }
 
nav #menu-icon {padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px; border:2px solid #000;}

  

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/

/*----------------------------Allt över 1300px----------------------------*/
@media (min-width: 1300px) 
{  
    .sektion p {font-size:1.1em; line-height:1.3em; }
}

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
.sektion { padding: 70px 0; }
 
.startsidatext {  margin-bottom: 20px;  padding: 20px 0;  }
.startsidatext h1 { font-size: 2.2em; padding:0; }
.startsidatext_textdiv { font-size: 1.3em;}
        
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
    h1 {font-size: 2.2em;}   
    
    .medarbetare h2 {font-size:1.3em; }   
    
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}
  
/*sektion*/   
.banner_sektion h1 {font-size: 2.8em;}
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
.sektion {  padding: 70px 0;  ;}
.sektion.full {min-height: 10vh;}
    
    
.startsidatext {  margin-bottom: 20px;  padding:15px 0;  }
.startsidatext h1 { font-size: 2em; }
.startsidatext_textdiv { font-size: 1.2em;}
    
    
    
 h1 {font-size: 2em;}   
 .medarbetare h2 {font-size:1.15em; } 
    .medarbetare_textdiv {font-size:0.9em; }   
    
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
/*Slider & topbild height*/  
.slick, .slick-slide, .topbild {min-height:55vw;}
  
.banner_sektion h1 {font-size: 2.4em;} 
    
.sektion { padding:40px 0; }
/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
.sidfot .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
.sidfot .s-twelve:last-child {border-bottom: none; }  
    
.arrow .fa { 
    font-size: 4em;
    height: 60px;
    margin: -30px 0 0 -30px;
    padding: 0;
    width: 60px;
 
}    
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
    .banner_sektion h1 {font-size: 2em;} 
.startsidatext h1 { font-size: 1.7em; }   
  .startsidatext_textdiv { display:none; }
    .toptext {display:none; }
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
/*bild*/
.bild {display:none; }     
 }

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/

/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}

















/*----------------------Zoomboxar-------------------------------
.zoombox { height:20vw; overflow:hidden; position:relative;}
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2); transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} */

/*----------------------Fadeboxar-------------------------------
.fadebox {height:20vw; overflow:hidden; display:block; background-size:cover; background-position: center center;}
.fadebox_innerdiv {height:100%; background:rgba(0, 0, 0, 0.6 ); opacity:0; transition: opacity 0.5s ease-in 0s; } 
.fadebox:hover .fadebox_innerdiv {opacity:1;} 
.fadebox_textdiv {padding: 0 2em; color:#fff; text-align: center; position: relative; }*/

/*--------------------Mediaquerys fadeboxar--------------------
@media (max-width: 1300px) 
{
.fadebox, .zoombox {height:23vw}
}
@media (max-width: var(--breakpoint-m))
{
.fadebox_textdiv span, .zoombox_textdiv span {display:none;}
.fadeboxar.container, .zoomboxar.container, .fadeboxar.container.lrg, .zoomboxar.container.lrg {width: 100%;} 
}
@media (max-width: var(--breakpoint-s))
{
.fadebox, .zoombox {height:30vw;}
.fadebox_innerdiv, .zoombox_textdiv {background:rgba(0, 0, 0, 0.3); opacity:1; color:#fff; }
.zoomboxar > a:hover .zoombox_innerdiv, .zoombox_innderdiv {transform:none;}
}
@media (max-width: var(--breakpoint-xs))
{
.fadebox, .zoombox {height:50vw;}
}

*/
